div.component.loan-form {
  div.two-column {
    @apply flex my-5 w-full gap-4;

    .col {
      @apply flex flex-col w-1/2;
    }
  }

  table.inputs-table {
    tbody {
      tr {
        td {
          @apply py-1 pr-2;

          > div.component.dollar-input, > div.component.percent-input, > div.component.input-with-icon {
            @apply max-w-48;
          }

          select.index {
            @apply w-full;
          }
        }
      }
    }
  }
}
