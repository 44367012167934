div.component.modal-container {
  @apply fixed z-30 inset-0 overflow-y-auto;

  div.modal-footer {
    @apply border-t;
  }

  .centered {
    @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;

    .overlay {
      @apply fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity;
    }

    .vert-centered {
      @apply hidden sm:inline-block sm:align-middle sm:h-screen;
    }

    .rounded-box {
      @apply inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle;
      //  sm:max-w-lg sm:w-full;

      .title {
        @apply bg-white px-4 py-3 text-lg font-medium;
      }
    }
  }
}
