table.treasury-services-view-table {
  @apply min-w-full divide-y divide-gray-200 table-auto;

  thead {
    th {
      @apply bg-gray-50 top-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider pr-2 whitespace-nowrap;
    }

    th:first-child {
      @apply pl-2;
    }

    th.right {
      @apply text-right pl-2;
    }
  }

  tbody {
    @apply bg-white divide-y divide-gray-200;

    tr {
      @apply hover:bg-gray-50;
    }

    tr.product-family-row {
      @apply bg-gray-100 hover:bg-gray-100;

      td.product-family {
        font-weight: bold;
      }

      td.edit {
        @apply top-0 text-left text-xs font-medium text-gray-100 uppercase tracking-wider pr-2 whitespace-nowrap hover:text-gray-400;
      }
    }

    td:first-child {
      @apply px-2;
    }

    td {
      @apply text-xs py-2;
    }

    input[type="checkbox"] {
      @apply w-4 h-4;
    }
  }
}
