table.component.opportunities-table {
  @apply min-w-full divide-y divide-gray-200;

  thead {
    th {
      @apply bg-gray-50 sticky top-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }

    th:first-child {
      @apply px-2;
    }
  }

  tbody {
    @apply bg-white divide-y divide-gray-200;

    tr {
      @apply hover:bg-gray-50 cursor-pointer;
    }

    td:first-child {
      @apply px-2;
    }

    td {
      @apply text-xs py-2;
    }
  }
}
