header.component.navigation-bar {
  @apply sticky top-0 left-0 right-0;
  @apply h-12;
  @apply flex flex-row justify-between items-center;
  @apply border-b border-gray-200 z-20 bg-white;
  @apply bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60;
  @apply px-2;

  div.content-group {
    @apply flex flex-row justify-between items-center;
    @apply p-0;
    @apply h-full;

    .logo {
      @apply px-4 pt-2;
      @apply cursor-pointer;

      img {
        @apply w-auto h-8;
      }
    }

    .menu-item {
      @apply flex flex-row items-center;
      @apply px-4 h-full;
      @apply cursor-pointer;
      @apply hover:bg-blue-600;
      @apply transition-colors duration-200;
      @apply hover:text-white;
    }
  }
}
