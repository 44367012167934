table.scenarios-comparator-table {
  @apply min-w-full divide-y divide-gray-200;

  thead {
    th {
      @apply px-2 bg-gray-50 top-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider max-w-0;
    }
  }

  colgroup {
    col.selected {
      @apply border-blue-500 border-2;
    }
  }

  tbody {
    @apply bg-white divide-y divide-gray-200;

    tr {
      &.netInterestIncome,
      &.pretaxIncome,
      &.netIncome {
        td {
          @apply border-t border-t-black pb-4;
        }
      }

      &.roa {
        td {
          @apply pt-4;
        }
      }
    }

    td:first-child {
      @apply pl-2;
    }

    td {
      @apply text-xs py-2 px-2;
    }
  }
}
