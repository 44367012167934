div.component.product-picker {
  @apply pb-2;

  .products {
    @apply m-4 grid grid-cols-3;

    .product {
      @apply m-1 h-20 bg-transparent hover:bg-blue-500 text-blue-500 text-base font-medium hover:text-white py-2 px-4 border border-blue-500 rounded;

      &.disabled {
        @apply bg-gray-100 text-gray-500 border-gray-500;
      }
    }
  }

  div.clone-prompt {
    @apply pl-4 pb-2;
  }
}
