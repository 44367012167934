div.component.message-composer {
  form {
    @apply flex items-start w-full p-1 bg-gray-100 border-t border-gray-200;

    textarea {
      @apply flex-1 p-1 text-sm bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent;
      @apply max-h-24 resize-none;

      &:disabled {
        @apply bg-gray-200;
      }
    }

    button {
      @apply ml-1 mt-1;

      &:disabled {
        @apply text-gray-400;
      }
    }
  }
}