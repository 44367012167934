div.pipeline-star svg {
  @apply absolute text-yellow-500 -inset-2;

  &.bigger {
    top: -13px;
    left: -12px;
    width: 30px;
    height: 30px;
    @apply text-white;
  }
}