.package {
  input[type="checkbox"] {
    @apply w-7;
  }

  .icon {
    @apply w-7;
  }

  span {
    @apply text-lg;
  }
}

.element {
  input[type="checkbox"] {
    @apply ml-7 w-7;
  }

  span {
    @apply text-sm;
  }
}
