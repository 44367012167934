div.products-picker {
  div.body {
    @apply p-4;
  }

  button.back-button {
    @apply block py-2 pl-2;
  }

  div.empty {
    @apply p-2;
  }
}
