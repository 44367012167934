div.component.right-dock {
  @apply fixed top-12 right-0 h-[calc(100vh-3rem)] z-40;
  @apply bg-white;
  @apply flex;

  .button-panel {
    @apply flex flex-col justify-between items-center text-blue-500 w-10 border-l-4 border-blue-100 py-2;
  }

  .expanded-panel {
    @apply border-blue-100 border-l-2 w-80;
  }
}
