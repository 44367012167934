div.modal.create-client {
  div.modal-container {
    div.centered {
      div.rounded-box {
        @apply overflow-visible;

        .title {
          @apply rounded-t-md;
        }

        div.content {
          @apply rounded-b-md overflow-auto;
        }
      }
    }

    div.component.client-form {
      table {
        @apply mx-4 my-4;
      }
    }
  }
}
