div.error-banner {
  @apply fixed z-50 inset-0 overflow-y-auto;

  div.centered {
    @apply flex items-center justify-center py-4 px-4 text-center bg-red-400;

    div.icon {
      @apply mr-4 text-yellow-100;
    }
  }
}

