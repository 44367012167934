table.scenarios-table {
  @apply min-w-full divide-y divide-gray-200;

  tbody {
    tr {
      &.instruction {
        @apply bg-gray-50;
      }
    }

    td:first-child {
      @apply pl-2;
    }

    td {
      @apply text-xs py-2;
    }

    input[type="checkbox"] {
      @apply w-4 h-4;
    }
  }
}
