table.income-statement-table {
  tbody {
    tr {
      td {
        @apply py-1 pr-2;
      }

      td:first-child {
        @apply pr-5;
      }
    }
  }

  tr.total {
    td {
      @apply border-t-2 border-gray-500;
    }
  }
}