div.edit-opportunity-modal {
  div.modal-container {
    div.centered {
      div.rounded-box {
        @apply overflow-visible;

        .title {
          @apply rounded-t-md;
        }

        div.content {
          @apply rounded-b-md overflow-auto;
        }
      }
    }
  }
}
