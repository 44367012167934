div.component.scenarios-picker {

  div.body {
    @apply p-4;
  }

  div.empty {
    @apply p-2;
  }

  button.back-button {
    @apply block py-2 pl-2;
  }
}
