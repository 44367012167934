div.component.message-list {
  @apply flex flex-col-reverse h-full w-full px-3 overflow-y-scroll;

  &.empty {
    @apply flex flex-col justify-center items-center;
  }

  div.load-more, div.loading-more {
    @apply my-2 self-center text-xs text-gray-500;
  }

  div.day {
    @apply flex flex-col my-2;

    div.date {
      @apply flex w-fit self-center justify-center content-center text-sm text-gray-500 px-2 rounded-full bg-gray-200;
    }

    div.message {
      @apply my-1;

      div.header {
        @apply flex flex-row justify-start gap-2 items-center mb-1;

        .user {
          @apply font-bold text-gray-700;
        }

        .timestamp {
          @apply text-xs text-gray-500;
        }
      }
    }
  }
}
